
@font-face {
  font-family: myH1Font;
  src: url(./assets/font/FogSansOutline-8wpJ.otf) format('opentype');
}

@font-face {
  font-family: myH1Font2;
  src: url(./assets/font/HussarNovaBlack-ZaP3.otf) format('opentype');
}


@font-face {
  font-family: myH5Font;
  src: url(./assets/font/ExoDemiBold-Jw3M.otf) format('opentype');
}


.page{
  position: relative;
  background: url("./assets/amethyst-bg2.jpg");
  width:100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}


.img-holder{
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.white{
  color: white;
}

a{
  text-decoration: none;
  font-weight: 600;
}

h1, h2, h3, h4, h5{
  line-height: 150%;
}

h5{
  font-size: 14px;
  line-height: 110%;

  @media only screen and (max-width: 600px) {
    font-size: 12px
  }

  @media only screen and (max-width: 495px) {
    font-size: 10px
  }

  @media only screen and (max-width: 420px) {
    font-size: 8px
  }

  @media only screen and (max-width: 340px) {
    font-size: 6px
  }

}

h4, h5{
  font-family: myH5font, "Comic Sans MS",  sans-serif;
}

h1{
  font-family: myH1font, "Courier New", Courier, monospace;
  font-size: 50px;
  font-weight: 600;

  &.foot-text{
    font-family: myH1font2, "Courier New", Courier, monospace;
    color: rgb(0, 0, 0);
  }

  &.offset{
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 3vw;
  }

  @media only screen and (max-width: 700px) {
    font-size: 40px
  }

  @media only screen and (max-width: 480px) {
    font-size: 30px
  }
}

h2{
  font-family: myH5font, "Comic Sans MS",  sans-serif;
  font-size: 30px;

  @media only screen and (max-width: 700px) {
    font-size: 22px
  }
}

h3{
  font-family: myH5font, "Comic Sans MS",  sans-serif;
  font-size: 25px;

  @media only screen and (max-width: 700px) {
    font-size: 15px
  }
}

.shrink{
  @media only screen and (max-width: 700px) {
    font-size: 12px;    
  }
}

.bold{
  font-weight: 600;

  strong{
    font-size: 28.5px;

    @media only screen and (max-width: 700px) {
      font-size: 18px
    }
  }
}

.grid20 { 
  display: grid;
  grid-template-columns: 30% 22.5% 22.5% 25%;
}

.grid50 { 
  display: grid;
  grid-template-columns: 50% 50%;
}

.grid70 { 
  display: grid;
  grid-template-columns: 70% 30%;
}

.grid55 { 
  display: grid;
  grid-template-columns: 55% 45%;
}

.grid80 { 
  display: grid;
  grid-template-columns: 27.5% 45% 27.5%;
}

.gridf75 { 
  display: grid;
  grid-template-columns: 25% 75%;
}

.grid100 { 
  display: grid;
  grid-template-columns: 100%;
}


.header-back{
  background-color: black;
  text-align: center;
}

.header{
  text-align: center;
  background-image: url(./assets/banner2.jpg);
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 120vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.centered{
  text-align: center;
}

.middle{
  margin: auto;
}

.size-holder{
  width: 93%;
  max-width: 1000px;
}

.pic-size{
  max-width: 800px;
}

.crystal-img{
    width: 100%;
    height: auto;

}

.backed{
  background-color: rgb(137, 235, 255);
  padding: 30px 30px 30px 30px;

  @media only screen and (max-width: 700px) {
    padding: 15px 15px 15px 15px;
  }
}

.backed2{
  background-color: rgb(137, 235, 255);
  padding: 10px 10px 10px 10px;

  @media only screen and (max-width: 700px) {
    padding: 5px 5px 5px 5px;
  }
}

.backed3{
  background-color: rgb(137, 235, 255);
  padding: 1px 30px 30px 30px;

  @media only screen and (max-width: 700px) {
    padding: 15px 15px 15px 15px;
  }
}


.backed-close{
  background-color: rgb(137, 235, 255);
  padding: 30px 30px 30px 30px;
  margin-top: 0;

  @media only screen and (max-width: 700px) {
    padding: 15px 15px 15px 15px;
  }
}

.backed-p-close{
  background-color: rgb(168, 137, 255);
  padding: 20px 20px 10px 20px;
  margin: auto;

  @media only screen and (max-width: 700px) {
    padding: 15px 15px 15px 15px;
  }
}

.backed-p{
  background-color: rgb(168, 137, 255);
  padding: 30px 30px 30px 30px;
  width: 60%;
  margin: auto;

  @media only screen and (max-width: 700px) {
    width: 90%;
    padding: 15px 15px 15px 15px;
  }
}

.backed-p2{
  background-color: rgb(168, 137, 255);
  padding: 10px 10px 10px 10px;
  width: 90%;
  margin: auto;

  @media only screen and (max-width: 700px) {
    width: 96%;
    padding: 5px 5px 5px 5px;
  }
}


.footer{
  background-image: url(./assets/footer2.jpg);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#footer{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.5rem;
}

.icon{
  background-color: red($color: #000000);
  max-width: 30px;
  
  @media only screen and (max-width: 500px){
    max-width: 20px;
  }
}